import React, { useCallback, useEffect, useState,useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAccessToken, getDbType, getMaid, getXrefToken } from "../utils";
import Loader from "./shared/Loader";
import { dataLayerPush } from "../utils/gtm";
import { useAppSelector } from "../app/hooks";
import { getIntimationRequest } from "../reducers/intimationRequest.slice";
import { getPlatform, getPlatformFromCookie } from "../common/helpers";
import { getBaseProfile } from "../reducers/baseProfile.slice";
import { NpsIntimation } from '@mediassistrepo/homepage-component';
import { EntityValue, FeedbackValue } from "../common/Nps/nps.models";
import { FeedBack, SetNpsFeedBack } from "../api/homeAPI";
import { Intimation } from "@mediassistrepo/intimation";

import "@mediassistrepo/intimation/dist/root.css";

export interface Base64Decryption {
    claimID?: number;
    intimationID?: number;
}

function IntimationComponent() {
    const navigate = useNavigate();
    const intimationRequest = useAppSelector(getIntimationRequest);
    const baseProfile = useAppSelector(getBaseProfile);
    const [searchParams] = useSearchParams();
    const accessToken = searchParams.get('accessToken') || getAccessToken();
    const source = searchParams.get('source') || "Maven";
    const dbType =  searchParams.get('dbType') || getDbType();
    const maid = searchParams.get('maid') || parseInt(getMaid());
    const oop = searchParams.get("oop");
    const API = process.env.REACT_APP_ELIGIBLITY_BASE_URL;

    
   
    const handleBackClick = () => {
        if(localStorage.getItem('isLinkPolicy') == 'true'){
            localStorage.setItem('isLinkPolicy','false')
            navigate("/");
        }
        else{
        navigate(-1);
        }
      };
    return (
        <React.Fragment>
            {accessToken && dbType && maid  &&  <div style={{ padding: "20px", paddingBottom: "0px"}}>
                <>
                    <Intimation 
                     maid={maid}
                     dbType={dbType}
                     isOOP={oop?.toLowerCase()==="true"}
                     token={accessToken}
                     request={JSON.stringify(intimationRequest)}
                     source={getPlatform()}
                     isAssumptionFlow={oop?.toLowerCase()==="true"}
                     handleBackClick={handleBackClick}
                     url = {API}
                     intimateSuccess={(   data:any)=>{
                        navigate('/oop?intimationID=' + data.IntimationID,{state: data})
                     }}
                     triggerGtmEvent={(e:any)=>{                         
                        e?.detail && dataLayerPush?.(e?.detail[0],e?.detail[1]);
                     }}
                     />
                 </>
            </div>}
        </React.Fragment>
    );
}

export default IntimationComponent;
