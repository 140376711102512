import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { getUpdateContactDetails } from '../api/updateContactAPI';

interface ContactInfo {
  isSuccess: boolean;
  data: {
    mobileNo: string[];
    emailId: string[];
  };
}

interface State {
  value: ContactInfo | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: State = {
  value: null,
  status: 'idle',
};

export const contactAsync = createAsyncThunk(
  'contact/contactAsync',
  async () => {
    const response = await getUpdateContactDetails()
    return response.data;
  }
);
  

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  
  reducers: {
    // increment: (state) => { 
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  
  
  extraReducers: (builder) => {
    builder
      .addCase(contactAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(contactAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(contactAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { } = contactSlice.actions;
export const getcontact = (state: RootState) => state.contact.value;
export const getcontactLoading = (state: RootState) => state.contact.status === "loading";

export default contactSlice.reducer;
