import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { getBeneficiaries } from '../reducers/beneficiaries.slice';
import { enrollmentAsync, getEnrollment } from '../reducers/enrollment.slice';
import FooterWeb from './shared/Footer';
import HeaderWeb from './shared/Header';
import Loader from './shared/Loader';

export const Iframe = styled.iframe.attrs(() => {
  return {
    className: 'w-full',
  };
})`
    min-height: 100vh;
  `;

const SingleEnrollment = () => {
  const [enrollStr, setEnrollStr] = useState<string>('');
  const enrollmentDetails = useAppSelector(getEnrollment);
  const beneficiariesResponse = useAppSelector(getBeneficiaries)
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();


  const handleStopLoading = () => {
    setIsLoading(false);
  }
  
  useEffect(() => {
    setIsLoading(true);
  }, [])

  useEffect(() => {
      if (!enrollmentDetails)
        dispatch(enrollmentAsync());
  }, []);


  useEffect(() => {
    let enrollData = localStorage.getItem('enrollStr');
    if (enrollData) {
      setEnrollStr(enrollData);
    }
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {/* <div className="has-sticky-header has-sticky-footer"> */}
        <div className="p-6"  style={{ marginTop:68}}>
          {enrollmentDetails?.data && <Iframe src={process.env.REACT_APP_ENROLLMENT_URL + enrollmentDetails.data} title="Enrollment" onLoad={handleStopLoading}></Iframe>}
        </div>
      {/* </div> */}
      {/* <FooterWeb /> */}
    </>
  )
}

export default SingleEnrollment;