import React, { useEffect, useState } from 'react'
import { getEmailDataApi, getMobileDataApi, updateEmailApi, updateMobileApi } from '../api/updateContactAPI';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { IContactOtpPayload, IMobileOtpUpdatePayload } from '../interfaces/updateContact.interface';
import { baseProfileAsync, getBaseProfile, isBaseProfileLoading } from '../reducers/baseProfile.slice';
import { configAsync, getConfig, isConfigLoading } from '../reducers/config.slice';
import { initialTimeout, useTimer } from '../utils/Timer';
import Loader from './shared/Loader';
import { UpdateContactDetails as UpdateContactDetailsComponent } from '@mediassistrepo/maven-old';
import { SIProtectDetailsRequest } from '../interfaces/account.interface';
import { getSIProtectDetails, isSIProtectLoading } from '../reducers/siProtect.slice';
import { siProtectChangeApi } from '../api/accountAPI';
import { siProtectDetailsAsync } from '../reducers/siProtect.slice';
import { useNavigate } from 'react-router';
import HeaderWeb from './shared/Header';
import { getCookieValue, postToNativeApp } from '../common/helpers';
import { dataLayerPush } from '../utils/gtm';
import Constants from '../constants/Constants';
import { getBeneficiaries } from '../reducers/beneficiaries.slice';
import { updateContactApi } from '../api/loginAPI';
import { contactAsync } from '../reducers/updateContact.slice';
import { BeneficiaryDetail } from '../interfaces/beneficiaries.interface';

const UpdateContactDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const corporateConfig = useAppSelector(getConfig);
  const isCorporateConfigLoading = useAppSelector(isConfigLoading);
  const baseProfile = useAppSelector(getBaseProfile);
  const isBaseProfLoading = useAppSelector(isBaseProfileLoading);
  const siProtectDetails = useAppSelector(getSIProtectDetails);
  const isSIProtectDetailsLoading = useAppSelector(isSIProtectLoading);
  const [isLoader, setIsLoader] = useState(false as boolean);
  const [mobileNoOtp, setmobileNoOtp] = useState(null as any);
  const [mobileOptError, setMobileOptError] = useState(false as any);
  const [methodMobile, setMethodMobile] = useState(false as boolean);
  const [otpRequested, setOtpRequested] = useState(false); // holds the status of otp request
  const [otpEmailRequested, setOtpEmailRequested] = useState(false); // holds the status of otp request
  
  const { seconds, setSeconds } = useTimer(otpRequested);
  const { seconds:emailSeconds, setSeconds:setEmailSeconds } = useTimer(otpEmailRequested);
  const [showEnterOtp, setShowEnterOtp] = useState(false as any);
  const [token, setToken] = useState({} as any);
  const [isShowModal, setIsShowModal] = useState(false as boolean);
  const [showEmailOtp, setShowEmailOtp] = useState(false as any);
  const [emailToken, setemailToken] = useState(null as any);
  const [emailOtp, setEmailOtp] = useState(null as any);
  const [emailOptError, setEmailOptError] = useState(false as any);
  const beneficiaryDetails = useAppSelector(getBeneficiaries);
  const [mobileNo, setMobileNo] = useState('' as string);
  const [emailId, setEmailId] = useState('' as string);
  
  useEffect(() => {
    if(!baseProfile){
      dispatch(baseProfileAsync)
    }
  },[])

  useEffect(() => {
    if(!siProtectDetails?.siProtectDetails){
      dispatch(siProtectDetailsAsync());
    }
    if(!corporateConfig){
      dispatch(configAsync())
    }
    if(!baseProfile?.data){
      dispatch(baseProfileAsync());
    }
  },[]);


  const updateContactAPI = async () => {
    
      setIsLoader(true) 
      let maid =beneficiaryDetails?.beneficiaryDetails.find((x:BeneficiaryDetail)=>x.relationToPrimaryId === 1)?.mediAssistId || baseProfile?.data?.maid;
      ;
      
      const paylad = {
        maid: maid,
        emailId: emailId || "", 
        phoneNumber: mobileNo || "", 
        altEmailId: "", 
        altMobile: "", 
        dbType: "MA",
        isContactsVerified: false,
      };
      
      try {
        setIsLoader(true)
        const response = await updateContactApi(paylad) as any;
        
        if(response?.isSuccess){
          dispatch(baseProfileAsync())
          dispatch(contactAsync());      
        }
        setIsLoader(false);
      } catch (error) {
        setIsLoader(false)
       
      }
    };

  const UpdateMobileFromApi = async (mobileOtp: any, mobileToken: any) => {
    let start = Date.now();
    let end, status;
    setIsLoader(true);
    const sendMobileUpdateUrl = `/profile/mobile-update`;
    const payload: IMobileOtpUpdatePayload = {
      otp: mobileOtp,
      token: mobileToken
    };
    try {
      let response = await updateMobileApi(payload);
      if (response.data.isSuccess) {
        end = Date.now();
        status = true;
        setmobileNoOtp(null);
        dispatch(baseProfileAsync());
        setIsLoader(false);
        setIsShowModal(true);
        updateContactAPI();
      } else {
        setIsLoader(false);
        setmobileNoOtp(null);
        end = Date.now();
        status = false;
        setMobileOptError(true)
      }
      setMethodMobile(true);
    } catch (err) {
      setIsLoader(false);
      end = Date.now();
      status = false;
    }
    let timeTaken = end - start;
    // gaContactDetails("ProfileContact_MobileValidateOTP",profile_Details,timeTaken);
  };

  const getMobileDataFromapi = async (validMobileNo: any) => {
    let start = Date.now();
    let end, status;
    setIsLoader(true);
    dataLayerPush(Constants.Instance.SEND_OTP_CTA_CLICK, { 
      cta_text:"Send OTP"
    });
    const sendMobileOtpUrl = `/profile/mobile-send-otp`;
    const payload: IContactOtpPayload = {
      mobile: validMobileNo,
    };
    setMobileNo(validMobileNo);
    try {
      let response = await getMobileDataApi(payload);
      if (response.data.isSuccess) {
        setIsLoader(false);
        end = Date.now();
        status = true;
        setOtpRequested(true);
        setSeconds(initialTimeout)
        setShowEnterOtp(true);
        setToken(response.data.token)
      } else {
        setIsLoader(false);
        // setOptError(response.error)
        end = Date.now();
        status = true;
      }

    } catch (err) {
      setIsLoader(false);
      end = Date.now();
      status = true;
    }
    let timeTaken = end - start;
    // gaContactDetails("ProfileContact_MobileSendOTPClick",profile_Details,timeTaken);
  };

  const handleSubscribe = async () => {
    setIsLoader(true);
    try {
      const req = new SIProtectDetailsRequest()
      if (methodMobile) {
        req.forMobile = true;
        req.subscribe = true;
        req.forEmail = (siProtectDetails?.siProtectDetails?.priBenefEmail) ? true : false;
        setMethodMobile(false);
      } else {
        req.forEmail = true;
        req.subscribe = true;
        req.forMobile = (siProtectDetails?.siProtectDetails?.priBenefMobileNumber) ? true : false;
      }
      let response = await siProtectChangeApi(req);
      if (response?.data?.isSuccess) {
        setIsLoader(false);
        dispatch(siProtectDetailsAsync());
      }
    } catch (err) {
      setIsLoader(false);
    }finally{
      setIsLoader(false);
    }
  }
  const handleLoader = (val: boolean) => {
    setIsLoader(val)
  }

  const handleSkip = () => {
    dispatch(baseProfileAsync());
    dispatch(configAsync());
    localStorage.setItem('notVerified','true');
    localStorage.setItem('isLinkPolicy','false')
    navigate("/");
  }

  const getEmailDataFromapi = async (validEmail: any) => {
    let start = Date.now();
    let end, status;
    handleLoader(true);
    dataLayerPush(Constants.Instance.SEND_OTP_CTA_CLICK, { 
      cta_text:"Send OTP"
    });
    const sendEmailOtpUrl = `/profile/email-send-otp`;
    const payload: IContactOtpPayload = {
      email: validEmail,
    };
    setEmailId(validEmail)
    try {
      let response = await getEmailDataApi(payload);
      if (response?.data?.isSuccess) {
        handleLoader(false);
        end = Date.now();
        status = true;
        setOtpEmailRequested(true)
        setEmailSeconds(initialTimeout)
        setShowEmailOtp(true);
        setemailToken(response?.data?.token)

      } else {
        handleLoader(false);
        end = Date.now();
        status = false;
      }
    } catch (err) {
      handleLoader(false);
      end = Date.now();
      status = false;
    }
    let timeTaken = end - start;
    // gaContactDetails("ProfileContact_EmailSendOTPClick",parentProps?.profile_Details,timeTaken);
  };

  const UpdateEmailFromApi = async (email: any, emailToken: any) => {
    let start = Date.now();
    let end, status;
    handleLoader(true);
    const sendMobileUpdateUrl = `/profile/email-update`;
    const payload: IMobileOtpUpdatePayload = {
      otp: email,
      token: emailToken
    };
    try {
      let response = await updateEmailApi(payload);
      if (response?.data?.isSuccess) {
        end = Date.now();
        status = true;
        setEmailOtp(null);
        setIsLoader(false);
        setIsShowModal(true);
        updateContactAPI()
      } else {
        handleLoader(false);
        setEmailOtp(null);
        end = Date.now();
        status = false;
        setEmailOptError(true);
      }
    } catch (err) {
      handleLoader(false);
      end = Date.now();
      status = false;
    }
    let timeTaken = end - start;
    // gaContactDetails("ProfileContact_EmailValidateOTP",parentProps?.profile_Details,timeTaken);
  };

  // if (isBaseProfLoading || isCorporateConfigLoading || isSIProtectDetailsLoading) return <Loader />

  return (
    <>
    <HeaderWeb isShowBackButton={true} oldHeader={window.innerWidth < 1024?true:false} name={"Update Contact"} />
      <UpdateContactDetailsComponent
        profile_Details={baseProfile?.data}
        policyConfigDetails={corporateConfig}
        isLoader={isLoader}
        setIsLoader={setIsLoader}
        mobileNoOtp={mobileNoOtp}
        setmobileNoOtp={setmobileNoOtp}
        showEnterOtp={showEnterOtp}
        setShowEnterOtp={setShowEnterOtp}
        token={token}
        setToken={setToken}
        mobileOptError={mobileOptError}
        setMobileOptError={setMobileOptError}
        otpRequested={otpRequested}
        setOtpRequested={setOtpRequested}
        seconds={seconds}
        setSeconds={setSeconds}
        emailSeconds={emailSeconds}
        otpEmailRequested={otpEmailRequested}
        setOtpEmailRequested={setOtpEmailRequested}
        setEmailSeconds={setEmailSeconds}
        UpdateMobileFromApi={UpdateMobileFromApi}
        getMobileDataFromapi={getMobileDataFromapi}
        isShowModal={isShowModal}
        handleSubscribe={handleSubscribe}
        methodMobile={methodMobile}
        setMethodMobile={setMethodMobile}
        siProtectDetails={siProtectDetails}
        setIsShowModal={setIsShowModal}
        showEmailOtp={showEmailOtp}
        setShowEmailOtp={setShowEmailOtp}
        emailToken={emailToken}
        setemailToken={setemailToken}
        emailOtp={emailOtp}
        setEmailOtp={setEmailOtp}
        emailOptError={emailOptError}
        setEmailOptError={setEmailOptError}
        UpdateEmailFromApi={UpdateEmailFromApi}
        getEmailDataFromapi={getEmailDataFromapi}
        handleSkip={handleSkip}
        handleLogout={() => {
          navigate("/logout")
        }}
      />
    </>
  )
}



export default UpdateContactDetails