import React, { useEffect, useState } from "react";
import {
  UpdateContact,
  UpdateContactModel,
} from "@mediassistrepo/homepage-component";
import { useNavigate } from "react-router";
import { getBeneficiaries } from "../../reducers/beneficiaries.slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { baseProfileAsync, getBaseProfile, isBaseProfileLoading } from "../../reducers/baseProfile.slice";
import { isConfigLoading } from "../../reducers/config.slice";
import { getConfig } from "@testing-library/react";
import { postToNativeApp } from "../../common/helpers";

const UpdateContactStrip = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const benefResponse = useAppSelector(getBeneficiaries);
  const [isvisible, SetIsVisible] = useState<boolean>(false);
  const [loginValueMobile, setLoginValueMobile] = useState<any>({
    mobile: "",
    isValid: false,
  });
  const [loginValueEmail, setLoginValueEmail] = useState<any>({
    email: "",
    isValid: false,
  });
  const baseProfileLoading = useAppSelector(isBaseProfileLoading);
  const baseProfile = useAppSelector(getBaseProfile)
  const configLoading = useAppSelector(isConfigLoading);
  const corporateConfig:any = useAppSelector(getConfig);


  useEffect(() => {
    if(!baseProfile){
      dispatch(baseProfileAsync());
    }

  }, []);

  
  useEffect(() => {
    
    postToNativeApp({ hideFooter: isvisible });
  }, [isvisible]);
  
 
  useEffect(() => {
    if(localStorage.getItem('isContact')=== "true" &&  benefResponse && baseProfile){
      setTimeout(()=>{
        SetIsVisible(true);
      },2000)
    }
  }, [ localStorage.getItem('isContact'),baseProfile,benefResponse]);

  

   useEffect(() => {
      try {
        if(baseProfile?.data?.isMobileVerified ||baseProfile?.data?.isEmailVerified){
          if(baseProfile?.data?.isMobileVerified){
            setLoginValueMobile({ mobile: baseProfile?.data?.mobileNo, isValid: true });
          }
          if(baseProfile?.data?.isEmailVerified){
            setLoginValueEmail({ email: baseProfile?.data?.emailId, isValid: true });
          }
          return;
        }
      } catch (error) {}
    }, [baseProfile]);

  const handleEditContacts = async (benefResponse: any) => {
    const pribenef = benefResponse?.beneficiaryDetails?.filter(
      (x: any) => x?.relationToPrimaryId === 1
    );

      navigate(
        `/updateContact`,
        { state: { familyData: pribenef } }
      );
  };

  const handleSkip = () => {
    SetIsVisible(false);
    localStorage.setItem("isContact", "false");
    // navigate("/");
  };

  return (
    <div style={{ padding: "20px 0px" }}>
      <div >
        <UpdateContact setIsVisiblsModel={SetIsVisible} />
      </div>
        <UpdateContactModel
          isVisibleModel={isvisible}
          setIsVisiblsModel={handleSkip}
          isPrimaryMobile={loginValueMobile?.isValid}
          primaryNo={loginValueMobile?.mobile}
          isPrimaryEmail={loginValueEmail?.isValid}
          primaryEmail={loginValueEmail?.email}
          handleProceed={() => {
            localStorage.setItem("isContact", "false");
            handleEditContacts(benefResponse);
          }}
        />
    </div>
  );
};

export default UpdateContactStrip;
