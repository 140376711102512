import { getRequest, patchRequest, postRequest } from "../app/postRequest.service";
import { IContactOtpPayload, IContactOtpRes, IMobileOtpUpdatePayload, IMobileOtpUpdateRes } from "../interfaces/updateContact.interface";

export function updateMobileApi(payload: IMobileOtpUpdatePayload){
  return new Promise<{ data: IMobileOtpUpdateRes }>((resolve) =>
    resolve(patchRequest('/profile/mobile-update',payload))
  );
}

export function updateEmailApi(payload: IMobileOtpUpdatePayload){
  return new Promise<{ data: IMobileOtpUpdateRes }>((resolve) =>
    resolve(patchRequest('/profile/email-update',payload))
  );
}

export function  getMobileDataApi(payload: IContactOtpPayload){
  return new Promise<{ data: IContactOtpRes }>((resolve) =>
    resolve(patchRequest('/profile/mobile-send-otp',payload))
  );
}

export function getEmailDataApi(payload: IContactOtpPayload){
  return new Promise<{ data: IContactOtpRes }>((resolve) =>
    resolve(patchRequest('/profile/email-send-otp',payload))
  );
}

export function getUpdateContactDetails() {
  return new Promise<{ data: any }>((resolve) =>
    resolve(getRequest("/user/contacts"))
  );
}
